import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldValues, useForm } from "react-hook-form";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { debounce, result } from "lodash";

import GoogleMap from "./map";
import ReactDOM from "react-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import useSWR from "swr";
import { validateCAPostalCode } from "../../utilities/validateCanadaPostalCode";

const getPartnersUrl = () => `/aeapi/getRetailers`;

interface GetPartnersPayload {
  zip: string;
  distance: number;
  country: string; // US or CANADA
}

export interface Pin {
  pos: google.maps.LatLng;
  distance: string;
  title: string;
  address: string;
  website: string;
  phone: string;
}

export enum SortOption {
  BY_NEAREST,
  BY_NAME,
}

export interface ActiveMarker {
  lat: number;
  lng: number;
}

const fetcher = (payload: GetPartnersPayload | undefined) => {
  if (!payload) {
    throw new Error("Please check your search parameters and try again");
  }
  return fetch(getPartnersUrl(), {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json() as Promise<any[]>);
};

const RadiusParam = withDefault(NumberParam, 15);
const CountryParam = withDefault(StringParam, "US");

export default function FindARetailer({
  portal,
}: {
  portal?: boolean | undefined;
}) {
  const supportsGeolocation = !!navigator.geolocation;
  const [zipCode, setZipCode] = useQueryParam("zipCode", StringParam);
  const [radius, setRadius] = useQueryParam("radius", RadiusParam);
  const [country, setCountry] = useQueryParam("country", CountryParam);
  const [activeMarker, setActiveMarker] = useState<ActiveMarker>();
  const [formDropdownStatus, setFormDropdownStatus] = useState<boolean>(true);
  const [resultsDropdownStatus, setResultsDropdownStatus] =
    useState<boolean>(true);
  const container = document.getElementById("findARetailer");

  // We use this ref callback to update our zip code text input whenever the params zipCode state object changes
  // (we do this on load, and whenever someone clicks the "use my location" button)
  const zipCodeInputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.value = zipCode ?? "";
      }
    },
    [zipCode]
  );

  // on zip code change, need to update the country if its CA or US
  useMemo(() => {
    if (zipCode) {
      const country = validateCAPostalCode(zipCode);
      setCountry(country);
    }
  }, [zipCode]);

  const [sort, setSort] = useState("nearest");

  let searchParams: GetPartnersPayload | undefined =
    !!zipCode && !!country && !!radius
      ? {
          zip: zipCode,
          country: country,
          distance: radius,
        }
      : undefined;

  // This is our fetch/service call which will load the retailers whenever our search parameters are updated
  const {
    data: retailers,
    error,
    isLoading,
    isValidating,
  } = useSWR(["getRetailers", searchParams], (vars) => fetcher(vars[1]), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  // We sort the retailers client-side because the API does not support sorting
  const sortedRetailers =
    retailers
      ?.sort((a, b) => {
        if (sort === "nearest") {
          return a.distance - b.distance;
        } else {
          return a.name.localeCompare(b.name);
        }
      })
      .map((r) => {
        return {
          pos: new window.google.maps.LatLng(r.lat, r.lgt),
          distance: `${Math.round(r.distance * 100) / 100} Miles`,
          title: r.name,
          address: `${r.addressLine1}<br/>
                    ${r.addressLine2 ? `${r.addressLine2}<br/>` : ""}
                    ${r.city} ${r.state} ${r.zip}`,
          website: r.website,
          phone: r.phone,
        } as Pin;
      }) ?? [];

  // The onClick handle for the "Use my location" button
  const useMyLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        // The PhaseZero API does not support searching by latitude and longitude, so we must pass the lat/lng to Google's
        // Geocoder API so we can get a postal code / country pair to search with
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            },
          },
          function (res, status) {
            if (status === "OK") {
              const result = res?.filter(
                (r) =>
                  r.address_components.some((a) =>
                    a.types.includes("postal_code")
                  ) &&
                  r.address_components.some((a) => a.types.includes("country"))
              )?.[0];
              if (result) {
                const zip = result.address_components.find((a) =>
                  a.types.includes("postal_code")
                )?.short_name;
                const country = result.address_components.find((a) =>
                  a.types.includes("country")
                )?.short_name;
                if (zip && country) {
                  setZipCode(zip);
                  setCountry(country === "CA" ? "CANADA" : country);
                }
              }
            }
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const zipCodeChangedHandler = (e: any) => {
    //   // Update our state for search paramters (and the URL query string)
    //   // Weird quirk with the zipCode parameter... The Autobatteries.com current site just takes the value in this search input and passes it into the "zipCode"
    //   // property. It would seem that PhaseZero's API has the ability to accept an address string within the "zipCode" property. I'm guessing they are using Google's
    //   // Geocoder API from their server code there as well.
    setZipCode(e.target.value);
    const country = validateCAPostalCode(e.target.value);
    setCountry(country);
  };

  const debouncedZipCodeChangedHandler = useMemo(
    () => debounce(zipCodeChangedHandler, 300),
    []
  );

  const countryChangedHandler = (e: any) => {
    setCountry(e.target.value);
  };

  const sortChangeHandler = (e: any) => {
    setSort(e.target.value);
  };

  const radiusChangeHandler = (e: any) => {
    setRadius(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedZipCodeChangedHandler.cancel();
    };
  }, []);

  const renderComponent = () => (
    <Wrapper apiKey={"AIzaSyBV4bCpqzc3e_2x7QeoKsHLrK_fAA8wiDc"}>
      <div className="retail-finder">
        <div className="retail-finder__rail">
          <h2 className="retail-finder__heading">
            <button
              type="button"
              className={`retail-finder__toggle ${
                formDropdownStatus ? "active" : ""
              }`}
              onClick={() => setFormDropdownStatus(!formDropdownStatus)}
            >
              Search Distributors
            </button>
          </h2>
          <div
            className={`retail-finder__dropdown ${
              formDropdownStatus ? "active" : ""
            }`}
          >
            <div className="retail-finder__group">
              <div className="retail-finder__group">
                <div className="row">
                  <div className="col-md-7">
                    <label
                      htmlFor="location-address"
                      className="retail-finder__label"
                    >
                      Specify Address, City or Zip / Postal Code
                    </label>
                  </div>
                  <div className="col-md-5">
                    {supportsGeolocation && (
                      <button
                        type="button"
                        className="retail-finder__button"
                        onClick={useMyLocation}
                      >
                        Use my location
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <input
                id="location-address"
                type="text"
                className="form-control"
                ref={zipCodeInputRef}
                placeholder="Specify Address, City or Zip / Postal Code"
                onChange={debouncedZipCodeChangedHandler}
              />
            </div>

            <div className="retail-finder__group">
              <label
                className="retail-finder__label"
                htmlFor="location-country"
              >
                Select Country
              </label>
              <div className="form__select-wrapper form__select-wrapper--secondary">
                <select
                  id="location-country"
                  value={country ?? "US"}
                  onChange={countryChangedHandler}
                  className="form-control form__select"
                >
                  <option disabled>Select Country</option>
                  <option value="US">US</option>
                  <option value="CANADA">CANADA</option>
                </select>
              </div>
            </div>

            <div className="retail-finder__group">
              <label className="retail-finder__label" htmlFor="location-radius">
                Distance Covered
              </label>
              <div className="form__select-wrapper form__select-wrapper--secondary">
                <select
                  id="location-radius"
                  value={radius ?? "15"}
                  onChange={radiusChangeHandler}
                  className="form-control form__select"
                >
                  <option disabled>Radius</option>
                  <option value="5">5 mi</option>
                  <option value="10">10 mi</option>
                  <option value="15">15 mi</option>
                  <option value="20">20 mi</option>
                  <option value="25">25 mi</option>
                  <option value="50">50 mi</option>
                  <option value="75">75 mi</option>
                  <option value="100">100 mi</option>
                </select>
              </div>
            </div>

            <div className="retail-finder__group">
              <label className="retail-finder__label" htmlFor="location-sort">
                Sort By
              </label>
              <div className="form__select-wrapper form__select-wrapper--secondary">
                <select
                  id="location-sort"
                  value={sort}
                  onChange={sortChangeHandler}
                  className="form-control form__select"
                >
                  <option value="nearest">Nearest</option>
                  <option value="name">Name</option>
                </select>
              </div>
            </div>
          </div>

          <h2 className="retail-finder__heading">
            <button
              className={`retail-finder__toggle retail-finder__toggle--results ${
                resultsDropdownStatus ? "active" : ""
              }`}
              type="button"
              onClick={() => setResultsDropdownStatus(!resultsDropdownStatus)}
            >
              Results ({sortedRetailers.length})
            </button>
          </h2>
          <div
            className={`retail-finder__dropdown  retail-finder__dropdown--results ${
              resultsDropdownStatus ? "active" : ""
            }`}
          >
            {isLoading || isValidating ? (
              <>
                <p>Loading...</p>
              </>
            ) : !!error && searchParams !== undefined ? (
              <>
                There has been an error with the search. Please try again, and
                if the issue persists - please contact our support team.
              </>
            ) : !!sortedRetailers && sortedRetailers.length > 0 ? (
              <>
                <ul className="retail-finder__list">
                  {sortedRetailers.map((item: any, index: number) => {
                    const address = { __html: item.address };
                    const latitude = item.pos.lat();
                    const longitude = item.pos.lng();
                    return (
                      <li
                        key={`${item.customerId}-${index}`}
                        className="retail-item"
                        onMouseOver={() =>
                          setActiveMarker({
                            lat: latitude,
                            lng: longitude,
                          })
                        }
                      >
                        <span className="retail-item__info">Miles</span>
                        <div className="retail-item__image">
                          <span className="retail-item__distance">
                            {index < 9 ? `0${index + 1}` : `${index + 1}`}
                          </span>
                        </div>
                        <h3 className="retail-item__title">{item.title}</h3>
                        <address
                          className="retail-item__address"
                          dangerouslySetInnerHTML={address}
                        ></address>
                        <a
                          href={`tel:${item.phone}`}
                          className="retail-item__link"
                        >
                          <svg aria-hidden="true" className="retail-item__icon">
                            <use href="#icon-phone"></use>
                          </svg>
                          {item.phone}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              zipCode !== undefined && (
                <>
                  <p className="retail-finder__no-results">
                    We are unable to find a distributor for entered zip / postal
                    code.
                  </p>
                </>
              )
            )}
          </div>
        </div>
        <div className="retail-finder__map">
          <GoogleMap
            retailers={retailers}
            activeMarker={activeMarker}
            radius={radius}
            zipCode={zipCode}
            country={country}
          />
        </div>
      </div>
    </Wrapper>
  );
  return portal
    ? container && ReactDOM.createPortal(renderComponent(), container)
    : renderComponent();
}
